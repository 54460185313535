
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { translate } from '../utils'

const ButtonMenu = () => {
    return (
        <Container className="mb-4" >
        <Row>
          <Col lg="12"  >
            <Row className="children-btn-profile ">
              <Col lg="2" sm="4" className="mb-2 offset-lg-2 ">
                <Button variant="dark" href="/auther-profile?url=edit" className="w-100  text-ceter">
                  {translate("Edit Profile")} 
                </Button>
              </Col>    

           
              <Col lg="2" sm="4" className="mb-2 ">
                <Button variant="dark"  href="/advices" className=" w-100  text-ceter">
                  {translate("List Advice")}
                </Button>
              </Col>
               <Col lg="2" sm="4" className="mb-2 ">
                <Button variant="dark"  href="/folders" className=" w-100  text-ceter">
                  {translate("List Foldes")}
                </Button>
              </Col>
               <Col lg="2" sm="4" className="mb-2 ">
                <Button variant="dark"  href="/videos" className=" w-100  text-ceter">
                  {translate("List Videos")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
}

export default ButtonMenu
