import React, { Component } from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import defaultAvatar from '../images/avatar.png'
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap"
import {
  ALERT_MSG_ERROR,
  AUTH_TOKEN_KEY,
  URL_DELETE_USER,
  BCE_JUR_FORM_NAME_BVBA,
  BCE_JUR_FORM_NAME_Commanditaire_Vennootschap,
  BCE_JUR_FORM_NAME_CV,
  BCE_JUR_FORM_NAME_Eenmanszaak,
  BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid,
  BCE_JUR_FORM_NAME_NV,
  BCE_JUR_FORM_NAME_Overige_rechtsvormen,
  BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma,
  BCE_JUR_FORM_NAME_VZW,
  convertddmmyyyyTommddyyyy,
  DOMAIN_API, firstLetterCapitalize,
  FR,
  getUserLanguage,
  NL,
  translate,
  URL_ADD_AVATARIMG_USER, URL_CHECK_AVATARIM_VALIDITY_USER,
  URL_DELETE_AVATARIMG_USER,
  URL_GET_NACEBELCODES,
  URL_GET_USER_FULL,
  URL_IS_AUTH,
  URL_MODIFY_USER
} from "../utils"
import axios from "axios"
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input'
import UserInterestsModal from "../components/userInterestsModal"
import UserPartnerModal from "../components/userPartnerModal"
import ModalAlert from "../components/modalAlert"
import Select from "react-select"
import ButtonMenu from "../components/ButtonMenu"
import $ from "jquery"
//code activité

class AutherProfile extends Component{
  state = {
    email: "",
    rue: "",
    numero_de_maison_ajout: "",
    advantagesTaked: [],
    avatarImg: '',
    lieu: "",
    nom_de_commerce: "",
    prenom_1: "",
    id: null,
    interests: "",
    nom_1: "",
    listOfPartners: null,
    numero_de_telephone: "",
    numero_d_enterprise: "",
    isDisabled: true,
    code_d_activite: '',
    activityDescription: '',
    nacebelCodes: [],
    showModalInterests: false,
    showModalPartners: false,
    userLanguage: NL,
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
    placeholderDate: '',
    date: '',
    oldDate: '',
    options: [],
    selectedValue: '',
    description_forme_juridique:'',
    valueFormJuridique: '',
    placeholderNacebel: '',
    ncrd_num:"",
    langue_operationnelle:"",
    sexe_1:"",
    role:""
  }
 

  componentDidMount = async () => {
    this.setState({
      placeholderNacebel: translate("sleutelwoord van uw branche"),
    })
    const userLanguage = getUserLanguage();
    if (userLanguage) await this.setState({userLanguage})
    this.setState({placeholderDate: translate('dd/mm/yyyy',false).toUpperCase()})
    await this.auth()
    await this.getNacebelCode()
    await this.getUser()

    ///preference open
    if (typeof window !== 'undefined') { 
      let wind= window.location.search;

      var preferenceopen  = new URLSearchParams(wind).get("preference");
       if(preferenceopen){
        this.setState({showModalPartners: true,showModalInterests: false})
       }
  
      }
//fin  /preference open
 
  }

  patchUserData = () => {
    const {prenom_1,nom_1,rue,numero_de_maison_ajout,
      lieu,numero_de_telephone,numero_d_enterprise,nom_de_commerce,date,oldDate,description_forme_juridique,code_d_activite, sexe_1, langue_operationnelle} = this.state;

    let newObject = {
      prenom_1,
      nom_1,
      rue,
      numero_de_maison_ajout,
      lieu,
      numero_de_telephone,
      numero_d_enterprise,
      nom_de_commerce,description_forme_juridique,
      code_d_activite,
      sexe_1,
      langue_operationnelle,
    }

    let newdate = oldDate;
    if (date !== '') {
      newdate = convertddmmyyyyTommddyyyy(date);
      newObject.establishmentDate = newdate
    }

    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = newObject;
    axios.patch(URL_MODIFY_USER,data,header)
      .then(res => {
        console.log("send",res.data)
      window.location.reload();
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data);
          this.setState({
            showAlert: true,
            alertTitle: ALERT_MSG_ERROR,
            alertMessage: err.response.data.message,
          })
        }
        console.log(err)
      });
  }

  getUser = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const url = URL_GET_USER_FULL;
    axios.get(url,data)
      .then(res => {
        if (res.data) {
          console.log('nouga data',res.data)
          const {
            rue,
            numero_de_maison_ajout,
            advantagesTaked,
            avatarImg,
            lieu,
            nom_de_commerce,
            prenom_1,
            id,
            //interest,
            listOfInterests,
            nom_1,
            listOfPartners,
            numero_de_telephone,
            numero_d_enterprise,
            code_d_activite,establishmentDate,description_forme_juridique,ncrd_num,langue_operationnelle,sexe_1} = res.data;

          const {userLanguage} = this.state;

          /*Forme juridique*/

          if (userLanguage === NL) {
            switch (description_forme_juridique) {
              case BCE_JUR_FORM_NAME_NV.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_NV.abreviationNL})
                break;
              case BCE_JUR_FORM_NAME_BVBA.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_BVBA.abreviationNL})
                break;
              case BCE_JUR_FORM_NAME_CV.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_CV.abreviationNL})
                break;
              case BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL})
                break;
              case BCE_JUR_FORM_NAME_VZW.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_VZW.abreviationNL})
                break;
              /**/
              case BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL})
                break;
              case BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL})
                break;
              case BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL})
                break;
              /**/
              case BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL})
                break;
              default:
                console.log(`forme juridique ${description_forme_juridique} not found`);
            }
          }else if (userLanguage === FR) {
            switch (description_forme_juridique) {
              case BCE_JUR_FORM_NAME_NV.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_NV.fromBceName})
                break;
              case BCE_JUR_FORM_NAME_BVBA.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_BVBA.fromBceName})
                break;
              case BCE_JUR_FORM_NAME_CV.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_CV.fromBceName})
                break;
              case BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName})
                break;
              case BCE_JUR_FORM_NAME_VZW.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_VZW.fromBceName})
                break;
              /**/
              case BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName})
                break;
              case BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName})
                break;
              case BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName})
                break;
              /**/
              case BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName:
                this.setState({valueFormJuridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName})
                break;
              default:
                console.log(`forme juridique ${description_forme_juridique} not found`);
            }
          }

          /*code activité*/
          if (userLanguage === FR) {
            for (const item of this.state.nacebelCodes) {
              if (item.code_d_activite === code_d_activite) {
                this.setState({selectedValue: {
                    label : item.description_d_activite,
                    value: item.id
                  }})
              }
            }

          }else {
            for (const item of this.state.nacebelCodes) {
              if (item.code_d_activite === code_d_activite) {
                this.setState({selectedValue: {
                    label : item.description_d_activite_NL,
                    value: item.id
                  }})
              }
            }
          }

          /*DATE*/
          let date = '';
          if (establishmentDate!==undefined && establishmentDate!==null && establishmentDate!=="") {
            const dateObject = new Date(establishmentDate);
            date = `${dateObject.getDate().toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}/${(dateObject.getMonth()+1).toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}/${dateObject.getFullYear()}`
          }

          /*INTEREST*/
          const arrayOfInterestName = [];
          if (userLanguage === FR) {
            listOfInterests.forEach(item => {
              arrayOfInterestName.push(firstLetterCapitalize(item.nameFR))
            })
          }else {
            listOfInterests.forEach(item => {
              arrayOfInterestName.push(firstLetterCapitalize(item.name))
            })
          }

          this.setState({
            email: res.data.account.email,
            rue,
            numero_de_maison_ajout,
            advantagesTaked,
            avatarImg,
            lieu,
            nom_de_commerce,
            prenom_1,
            id,
            //interests : (interests)?interests:[],
            interests: arrayOfInterestName,
            nom_1,
            listOfPartners: (listOfPartners)?listOfPartners:[],
            numero_de_telephone,
            numero_d_enterprise,
            code_d_activite,
            date: date,
            oldDate: establishmentDate,
            description_forme_juridique,
            ncrd_num,
            langue_operationnelle,
            sexe_1
          })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
        }
        console.log(err)
      });
  }

  getNacebelCode = async () => {
    const {userLanguage} = this.state;
    await axios.get(URL_GET_NACEBELCODES)
      .then(async res => {
        if (res.data) {
          await this.setState({nacebelCodes: res.data})

          this.state.nacebelCodes.forEach(item => {
            if (item.code_d_activite === this.state.code_d_activite) {
              this.setState({ activityDescription: (userLanguage === FR)?item.description_d_activite:item.description_d_activite_NL})
            }
          })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
        }
        console.log(err)
      });
    const options = this.state.nacebelCodes.map(item => ({
      label:  (userLanguage === FR)?item.description_d_activite:item.description_d_activite_NL,
      value: item.id
    }));
    //console.log(options);
    this.setState({ options : options });
  }

  fileSelectedHandler = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      const fd = new FormData();
      fd.append('avatarImg',file,file.name)
      const token = localStorage.getItem(AUTH_TOKEN_KEY)
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      }

      axios.post(URL_CHECK_AVATARIM_VALIDITY_USER,fd,header)
        .then(res => {
          if (res.data) {
            if (res.data === true) {
              axios.delete(URL_DELETE_AVATARIMG_USER,header)
                .then(res => {
                  if (res.data) {
                    console.log(res.data)
                  }
                  axios.post(URL_ADD_AVATARIMG_USER,fd,header)
                    .then(res => {
                      if (res.data) {
                        console.log(res.data)
                      }
                      console.log('update succesfull')
                      window.location.reload();
                    })
                    .catch(err => {
                      if (err.response && err.response.data) {
                        console.log(err.response.data);
                        this.setState({
                          showAlert: true,
                          alertTitle: ALERT_MSG_ERROR,
                          alertMessage: err.response.data.message,
                        })
                      }
                      console.log(err)
                      setTimeout(() => window.location.reload(),3000)
                    })
                })
                .catch(err => {
                  if (err.response && err.response.data) {
                    console.log(err.response.data);
                    this.setState({
                      showAlert: true,
                      alertTitle: ALERT_MSG_ERROR,
                      alertMessage: err.response.data.message,
                    })
                  }
                  console.log(err)
                })
            }
          }
        })
        .catch(err => {
          if (err.response && err.response.data) {
            console.log(err.response.data);
            this.setState({
              showAlert: true,
              alertTitle: ALERT_MSG_ERROR,
            })
            if (err.response.data.message && err.response.data.message.includes('1.00 (avatarImg)')) {
              this.setState({alertMessage: translate('let op: het formaat van uw avatar moet vierkant zijn')})
            }else this.setState({alertMessage: err.response.data.message})
          }
          console.log(err)
        })
    }
  }

  auth = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(URL_IS_AUTH, data)
      .then(res => {
        if (res.data) {
          const { id, email, role } = res.data
          this.setState({ accountId: id })
          this.setState({ email: email })
          this.setState({ role: role })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else console.log(err)
        window.location.href = "/login"
      })
  }

  onChangeJuridichSelect = (value) => {
    const {userLanguage} = this.state
    if (userLanguage === FR) {
      switch (value) {
        case BCE_JUR_FORM_NAME_NV.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_NV.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_NV.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_BVBA.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_BVBA.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_BVBA.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_CV.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_CV.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_CV.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_VZW.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_VZW.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_VZW.fromBceName
          })
          break;
        /**/
        case BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName
          })
          break;
        /**/
        case BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName,
            description_forme_juridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName
          })
          break;
        default:
          console.log(`forme juridique ${value} not found`);
      }
    }else {

      switch (value) {
        case BCE_JUR_FORM_NAME_NV.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_NV.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_NV.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_BVBA.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_BVBA.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_BVBA.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_CV.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_CV.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_CV.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_VZW.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_VZW.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_VZW.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName
          })
          break;
        case BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL:
          this.setState({
            valueFormJuridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL,
            description_forme_juridique: BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName
          })
          break;
        default:
          console.log(`forme juridique ${value} not found`);
      }
    }

  }

  onselectChange(value) {
    this.setState({selectedValue : value });
    let code_d_activite = ''
    this.state.nacebelCodes.forEach(item => {
      //console.log('value',typeof value)
      //console.log('item',typeof item.id)
      if (value.value === item.id) code_d_activite = item.code_d_activite;
    })
    this.setState({code_d_activite})
  }

  removeToUser= () => {

    let form = new FormData()
    form.append("id", this.state.id)
    form.append("email", this.state.email)
    form.append("token", 'NjkiEN#kkd')
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    }
        ///cherif
        axios
        .post(URL_DELETE_USER, form, header)
        .then(res => {
          localStorage.removeItem(AUTH_TOKEN_KEY);
           window.location.href = '/';
        })
        .catch(err => {
          if (err.response && err.response.data) { 
          }
          console.log(err) 
        })
      ///
  }
  render() {
  
    
    const {
      email,
      rue,
      numero_de_maison_ajout,
      advantagesTaked,
      avatarImg,
      lieu,
      nom_de_commerce,
      prenom_1,
      id,
      interests,
      nom_1,
      listOfPartners,
      numero_de_telephone,
      numero_d_enterprise,
      isDisabled,
      code_d_activite,
      activityDescription,
      showModalInterests,
      showModalPartners,
      showAlert,alertMessage,alertTitle,placeholderDate,date,userLanguage,
      valueFormJuridique,placeholderNacebel,ncrd_num,langue_operationnelle,sexe_1
    } = this.state;

    return (
      <Layout>
         {(typeof document !== 'undefined')?(this.state.showModalInterests)?document.body.classList.add('body-modal-open1'):(typeof document !== 'undefined')?document.body.classList.remove('body-modal-open1'):null:null}
         {(typeof document !== 'undefined')?(this.state.showModalPartners)?document.body.classList.add('body-modal-open2'):(typeof document !== 'undefined')?document.body.classList.remove('body-modal-open2'):null:null}

        {((interests) && showModalInterests)?       
          <UserInterestsModal
            closeModal={() => this.setState({showModalInterests: false})}
            userInterests={interests}
          />:null
        }
        {((listOfPartners) && showModalPartners)?
         <UserPartnerModal
          closeModal={() => this.setState({showModalPartners: false})}
          userPartners={listOfPartners}
         />:null}
        <div className="bg-white">
          <Header />
          <Container className="auther-profile-wrap mb-5 pb-5 f23">
            {/* <ButtonMenu/> */}
            <Row>
              <Col xl="3">
                <div className="d-flex justify-content-center justify-content-xl-end">
                  <div className="auther-profile mb-4" style={{position: 'relative'}}>
                    <img
                      //onClick={() => alert('Coming soon!')}
                      className="rounded-circle img-fluid"
                      src={(avatarImg!=="")?DOMAIN_API()+avatarImg:defaultAvatar}
                      alt=""
                    />
                    <input className="PicProfil"
                      style={{position: 'absolute',width:'100%',height: '100%',borderRadius: '65px',opacity:'0',cursor: 'pointer'}}
                      type={'file'}
                      onChange={e => this.fileSelectedHandler(e)}
                    />
                  </div>
                </div>
              </Col>
              <Col xl="7">
                <h1 className="h1-title text-center text-xl-left">{translate('profiel')}</h1>
                {/*<div className="orange-widgets-box-profile rounded-lg mt-4 mb-5  text-center">
                  <h1 className="h1-title">
                    {translate('wilt u meer voordelen ontvangen van onze partners')+"?"}
                  </h1>
                  <p>
                    {translate('maak uw selectie van partners en interesses compleet')}
                  </p>
                  <Button variant="outline-secondary">
                    Maak mijn selectie compleet
                  </Button>
                </div>*/}
                <Form>
                  <Row>
                    <Col xl="12">
                      <h4 className="h4-title">{translate("gebruikersinformatie",false,false)}</h4>
                    </Col>
                    {/*<Col md="6">
                      <Form.Group>
                        <Form.Label>E-mail adres</Form.Label>
                        <Form.Control type="text" placeholder="jesse@example.com" />
                      </Form.Group>
                    </Col>*/}
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate("naam")}</Form.Label>
                        <Form.Control
                          value={nom_1}
                          onChange={(e) => this.setState({nom_1: e.target.value})}
                          disabled={isDisabled} type="text"/>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate('voornaam')}</Form.Label>
                        <Form.Control
                          value={prenom_1}
                          onChange={(e) => this.setState({prenom_1: e.target.value})}
                          disabled={isDisabled} type="text" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate("E-mail adres")}</Form.Label>
                        <Form.Control
                          disabled={true}
                          value={email}
                          onChange={(e) => this.setState({email: e.target.value})}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
 

                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate("kaartnummer")}</Form.Label>
                        <Form.Control
                          disabled={true}
                          value={ncrd_num}
                          type="text"
                        />
                      </Form.Group>
                      </Col> 
                    <Col md="6"> 
                        <Form.Group  >
                        <Form.Label>{translate("genre")}</Form.Label>
                        <br />
                        <Form.Check
                          inline 
                          label={translate("femme")} 
                          value="F"
                          type="radio"
                          disabled={isDisabled}
                          name="sexe_1"
                          checked={this.state.sexe_1==="F"}
                          onChange={(e) => this.setState({sexe_1: "F"})}
                        />
                         <Form.Check
                          inline 
                          label={translate("homme")}
                          value="M"
                          type="radio"
                          name="sexe_1"
                          disabled={isDisabled}
                          checked={this.state.sexe_1==="M"}
                          onChange={(e) => this.setState({sexe_1: "M"})}
                        />
                      
                      </Form.Group>
                    </Col> 
                    <Col md="6"> 
                      <Form.Group>
                        <Form.Label>{translate("langue")}</Form.Label>
                        <br />
                        <Form.Check
                          inline 
                          label="FR" 
                          value="FR"
                          type="radio"
                          disabled={isDisabled}
                          name="langue_operationnelle"
                          checked={this.state.langue_operationnelle==="FR"}
                          onChange={(e) => this.setState({langue_operationnelle: "FR"})}
                        /> 
                        <Form.Check
                          inline 
                          label="NL" 
                          value="NL"
                          type="radio"
                          disabled={isDisabled}
                          name="langue_operationnelle"
                          checked={this.state.langue_operationnelle==="NL"}
                          onChange={(e) => this.setState({langue_operationnelle: "NL"})}
                        /> 
                        {/*<Form.Check
                          inline 
                          label="EN" 
                          value="EN"
                          type="radio"
                          disabled={isDisabled}
                          name="langue_operationnelle"
                          checked={this.state.langue_operationnelle==="EN"}
                          onChange={(e) => this.setState({langue_operationnelle: "EN"})}
                        />*/}
                      
                      </Form.Group>
                    </Col>

                    <Col xl="12">
                      <h4 className="h4-title">{translate('contactgegevens',false,false)}</h4>
                    </Col>
                    <Col md="8">
                      <Form.Group>
                        <Form.Label>{translate('adres')}</Form.Label>
                        <Form.Control
                          value={rue}
                          onChange={(e) => this.setState({rue: e.target.value})}
                          disabled={isDisabled}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>{translate('nummer')}</Form.Label>
                        <Form.Control
                          value={numero_de_maison_ajout}
                          onChange={(e) => this.setState({numero_de_maison_ajout: e.target.value})}
                          disabled={isDisabled} type="text"/>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate("stad")}</Form.Label>
                        <Form.Control
                          value={lieu}
                          onChange={(e) => this.setState({lieu: e.target.value})}
                          disabled={isDisabled} type="text"/>
                      </Form.Group>
                    </Col>
                    {/*<Col md="6">
                      <Form.Group>
                        <Form.Label>Regio</Form.Label>
                        <Form.Control
                          value={region}
                          onChange={(e) => this.setState({region: e.target.value})}
                          disabled={isDisabled} type="text" placeholder="Brussels" />
                      </Form.Group>
                    </Col>*/}
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate('telefoonnummer')}</Form.Label>
                        {/* <Form.Control
                          value={numero_de_telephone}
                          onChange={(e) => this.setState({numero_de_telephone: e.target.value})}
                          disabled={isDisabled} type="text"/> */}
                        <Input
                          className="auther-profile-wrap form-group form-control"
                          initialValueFormat="national"
                          defaultCountry="BE"
                          value={numero_de_telephone}
                          onChange={(value) => this.setState({numero_de_telephone: value})}
                          error={(value) => value && isValidPhoneNumber(value) ? 'true' : 'false'}
                          disabled={isDisabled}
                        />
                      </Form.Group>
                    </Col>
                    {/*<Col md="6">
                      <Form.Group>
                        <Form.Label>Tweede telefoonnummer</Form.Label>
                        <Form.Control
                          value={phone2}
                          onChange={(e) => this.setState({phone2: e.target.value})}
                          disabled={isDisabled} type="text" placeholder="0498 23 13 20" />
                      </Form.Group>
                    </Col>*/}
                    <Col xl="12">
                      <h4 className="h4-title">{translate("bedrijfsinformatie",false,false)}</h4>
                    </Col>
                    <Col md="8">
                      <Form.Group>
                        <Form.Label>{translate('bedrijfsnaam')}</Form.Label>
                        <Form.Control
                          value={nom_de_commerce}
                          onChange={(e) => this.setState({nom_de_commerce: e.target.value})}
                          disabled={isDisabled} type="text"/>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>{translate("bedrijfnummer")}</Form.Label>
                        <Form.Control
                          value={numero_d_enterprise}
                          onChange={(e) => this.setState({numero_d_enterprise: e.target.value})}
                          disabled={isDisabled}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    {/*<Col md="6">
                      {/*<Form.Group onClick={() => alert('Coming soon!')}>
                        <Form.Label>Sector</Form.Label>
                        <Form.Control disabled={true} type="text" placeholder="Sector" />
                      </Form.Group>*/}
                    {/*<Form.Group>
                        <Form.Label>{translate("beschrijving van de activiteit")}</Form.Label>
                        <Form.Control disabled={true} value={activityDescription} type="text" />
                      </Form.Group>
                    </Col>*/}
                    <Col md="6">
                      {/*<Form.Group onClick={() => alert('Coming soon!')}>
                        <Form.Label>Sector</Form.Label>
                        <Form.Control disabled={true} type="text" placeholder="Sector" />
                      </Form.Group>*/}
                      <Form.Group>
                        <Form.Label>{translate("datum van oprichting")}</Form.Label>
                        <Form.Control
                          disabled={isDisabled}
                          value={date}
                          placeholder={placeholderDate}
                          onChange={(e) => this.setState({date: e.target.value})}
                        />
                      </Form.Group>
                    </Col>
                    {/*<Col md="6">
                      <Form.Group onClick={() => alert('Coming soon!')} >
                        <Form.Label>Interesses</Form.Label>
                        <Form.Control value={interests} disabled={true} type="text" placeholder="Lucky" />
                      </Form.Group>
                    </Col>*/}
                    <Col md="6">
                    <label className="form-label">{translate("Vormen van samenleving")} </label>
                      <Form.Group> <div className="pure-css-select-style theme-default">
                        {(userLanguage === FR)?
                          (valueFormJuridique!=='')?
                            <Form.Control disabled={isDisabled} as="select" onChange={(e) => this.onChangeJuridichSelect(e.target.value)}>
                              <option>{valueFormJuridique}</option>
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_NV.fromBceName)?<option>{BCE_JUR_FORM_NAME_NV.fromBceName}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_BVBA.fromBceName)?<option>{BCE_JUR_FORM_NAME_BVBA.fromBceName}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_CV.fromBceName)?<option>{BCE_JUR_FORM_NAME_CV.fromBceName}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName)?<option>{BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_VZW.fromBceName)?<option>{BCE_JUR_FORM_NAME_VZW.fromBceName}</option>:null}
                              {/**/}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName)?<option>{BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName)?<option>{BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName)?<option>{BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName}</option>:null}
                              {/**/}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName)?<option>{BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName}</option>:null}
                            </Form.Control>:
                            <Form.Control disabled={isDisabled}  as="select" onChange={(e) => this.onChangeJuridichSelect(e.target.value)} >
                              <option >{BCE_JUR_FORM_NAME_NV.fromBceName}</option>
                              <option>{BCE_JUR_FORM_NAME_BVBA.fromBceName}</option>
                              <option>{BCE_JUR_FORM_NAME_CV.fromBceName}</option>
                              <option>{BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName}</option>
                              <option>{BCE_JUR_FORM_NAME_VZW.fromBceName}</option>
                              {/**/}
                              <option>{BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.fromBceName}</option>
                              <option>{BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName}</option>
                              <option>{BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName}</option>
                              {/**/}
                              <option>{BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName}</option>
                            </Form.Control>

                          :

                          (valueFormJuridique!=='')?
                            <Form.Control disabled={isDisabled} as="select" onChange={(e) => this.onChangeJuridichSelect(e.target.value)}>
                              <option>{valueFormJuridique}</option>
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_NV.abreviationNL)?<option>{BCE_JUR_FORM_NAME_NV.abreviationNL}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_BVBA.abreviationNL)?<option>{BCE_JUR_FORM_NAME_BVBA.abreviationNL}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_CV.abreviationNL)?<option>{BCE_JUR_FORM_NAME_CV.abreviationNL}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_VZW.abreviationNL)?<option>{BCE_JUR_FORM_NAME_VZW.abreviationNL}</option>:null}
                              {/**/}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL}</option>:null}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL}</option>:null}
                              {/**/}
                              {(valueFormJuridique!== BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL)?<option>{BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL}</option>:null}
                            </Form.Control>:
                            <Form.Control disabled={isDisabled}  as="select" onChange={(e) => this.onChangeJuridichSelect(e.target.value)}>
                              <option value={BCE_JUR_FORM_NAME_NV.fromBceName}>{BCE_JUR_FORM_NAME_NV.abreviationNL}</option>
                              <option value={BCE_JUR_FORM_NAME_BVBA.fromBceName}>{BCE_JUR_FORM_NAME_BVBA.abreviationNL}</option>
                              <option value={BCE_JUR_FORM_NAME_CV.fromBceName}>{BCE_JUR_FORM_NAME_CV.abreviationNL}</option>
                              <option value={BCE_JUR_FORM_NAME_Eenmanszaak.fromBceName}>{BCE_JUR_FORM_NAME_Eenmanszaak.abreviationNL}</option>
                              <option value={BCE_JUR_FORM_NAME_VZW.fromBceName}>{BCE_JUR_FORM_NAME_VZW.abreviationNL}</option>
                              {/**/}
                              <option value={BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.fromBceName}>{BCE_JUR_FORM_NAME_Commanditaire_Vennootschap.abreviationNL}</option>
                              <option value={BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.fromBceName}>{BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid.abreviationNL}</option>
                              <option value={BCE_JUR_FORM_NAME_Overige_rechtsvormen.fromBceName}>{BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma.abreviationNL}</option>
                              {/**/}
                              <option>{BCE_JUR_FORM_NAME_Overige_rechtsvormen.abreviationNL}</option>
                            </Form.Control>}
                      </div> </Form.Group>
                    </Col>
                    <Col lg="6" >
                      <Form.Group>
                        <Select
                          isDisabled={isDisabled}
                          placeholder={placeholderNacebel}
                          options={this.state.options}
                          onChange={(value) => this.onselectChange(value)}
                          value={this.state.selectedValue}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="12">
                      {(isDisabled)?
                        <Button
                          className="form-btn"
                          variant="success"
                          onClick={() => this.setState({isDisabled: false})}
                          //type="submit"
                        >
                          {translate('klik om te bewerken')}
                        </Button>:
                        <Button
                          onClick={() => this.patchUserData()}
                          className="form-btn"
                          variant="primary"
                          //type="submit"
                        >
                          {translate('aanvaarden-bis')}
                        </Button>
                      }
                      <a href={'/change-password'}>
                        <Button
                          className="form-btn"
                          variant="primary"
                          //type="submit"
                        >
                          {translate("wachtwoord wijzigen")}
                        </Button>
                      </a>
                    </Col>
                    { this.state.role!="partnersuper" ?
                    <Col xl="12" style={{color: '#9A9A9A',marginTop: '20px',textDecoration: 'underline',fontSize: '13px'}}>
                      <a onClick={() => this.setState({showModalPartners: true,showModalInterests: false})} style={{marginRight: '30px',cursor: 'pointer'}}>{translate('partner voorkeur')}</a>
                      <a onClick={() => this.setState({showModalInterests: true,showModalPartners: false})} style={{marginRight: '30px',cursor: 'pointer'}} >{translate('voorkeuren interesses')}</a>
                      <a  onClick={() => {if(window.confirm(translate("Êtes-vous sûr de vouloir supprimer le compte?"))){this.removeToUser()};}}  style={{cursor: 'pointer'}} >{translate('Supprimer mon compte')}</a>
                    </Col>
                    :
                    ""
                    }
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        {(showAlert)?
          <ModalAlert
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            closeModal={() => this.setState({showAlert: false})}
          />:null
        }
      </Layout>
    )
  }
}

export default AutherProfile
